.app {
  /* text-align: center; */
  /* min-height: 70vh; */
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes icon {
  0% {
    transform: translateX(-3px) scale(1);
  }

  50% {
    transform: translateX(0px) scale(1);
  }

  100% {
    transform: translateX(3px) scale(1);
  }
}

@keyframes times {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  45% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(-3px);
  }

  75% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Button-transform {
  0% {
    /* transform: translateX(8px) scale(1); */
    transform: scale3d(1, 1, 1);
    box-shadow: rgb(50 50 93 / 25%) 0px 0px 27px -5px, rgb(0 0 0 / 30%) 0px 7px 16px -8px;
  }

  50% {
    transform: scale3d(1.04, 1.04, 1);
    box-shadow: rgb(50 50 93 / 25%) 0px 8px 30px -5px, rgb(0 0 0 / 30%) 0px 7px 16px -8px;
  }

  100% {
    transform: scale3d(1.07, 1.07, 1);
    box-shadow: rgb(50 50 93 / 25%) 0px 16px 37px -5px, rgb(0 0 0 / 30%) 0px 7px 16px -8px;
  }
}

.app-content {
  height: calc(100vh - 48px);
  width: 100vw;
} 

.app-header {
  height: 48px !important;
}

.create-form-content {
  margin: 0px !important;
  max-height: calc(100vh - 116px - 24vh);
  overflow: auto;
  width: 100% !important;
}

.MuiDataGrid-actionsCell {
  gap: 0px !important;
  grid-gap: 0px !important;
}

.MuiStepLabel-label.Mui-active {
  color: royalblue !important;
  font-weight: 600 !important;
}

.MuiStepIcon-root.Mui-active {
  border: 5px solid lavender !important;
  border-radius: 22px !important;
  font-size: 2em !important;
}

.guide-me-on-left {
  position: fixed !important;
  bottom: 10px;
  left: 0vw;
  width: fit-content;
  z-index: 1000;
  background: antiquewhite !important;
  color: black !important;
  font-weight: 600 !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  box-shadow: none !important;
}

.guide-me-on-left:hover {
  font-size: 1em;
  padding: 8px 18px 8px 18px;
  bottom: 6px;
  border-right: 2px solid #e1d3c1 !important;
  border-top: 1px solid antiquewhite !important;
  border-bottom: 1px solid #e1d3c1 !important;
}

.guide-me {
  position: absolute !important;
  top: 10px;
  left: 0vw;
  width: fit-content;
  background: antiquewhite !important;
  color: black !important;
  font-weight: 600 !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  box-shadow: none !important;
}

.guide-me:hover {
  font-size: 1rem;
  padding: 8px 18px 8px 18px;
  top: 6px;
  border-right: 2px solid #e1d3c1 !important;
  border-top: 1px solid antiquewhite !important;
  border-bottom: 1px solid #e1d3c1 !important;
}