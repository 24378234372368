.module {
    padding: 0px 25px;
}

.ma-lr {
    margin-left: auto !important;
    margin-right: auto !important;
}

.m-lr-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.top-margin {
    margin-top: 5rem;
}

.avatar-mb {
    margin-bottom: 10px;
}

.bottom-margin {
    margin-bottom: 2rem;
}

.module-content {
    border-top: 5px solid #1976d2;
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.user-info {
    padding-left: 0;
    list-style: none;
}

.user-thumnail {
    margin-bottom: 10px;
}

.img-wrap {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.img-upload:before {
    content: '';
    display: block;
    background-image: url(https://img.icons8.com/clouds/100/000000/upload.png);
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center;
    border-radius: 4px;
    position: absolute;
    padding-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 200px;
    height: 200px;
    opacity: 0;
    transition: .5s ease;
    color: #fff;
    backdrop-filter: blur(10px);
}

.img-upload:hover:before {
    opacity: 1;
}

.img-block {
    width: auto !important;
    height: 100% !important;
    cursor: pointer;
}