.report-container * {
    transition: all 0.3s ease-in-out;
}

.report-container {
    width: 100%;
    height: 100%;
    display: block;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
    transform: scale(1);
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 45px -1px,
        rgb(0 0 0 / 4%) 0px 0px 0px -8px !important
}

.report-container:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%),
        0 50px 100px -20px rgb(50 50 93 / 25%),
        0 30px 60px -30px rgb(0 0 0 / 30%) !important;
}

.reportTitle {
    color: #262626 !important;
    font-size: 17px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    margin-bottom: 4px !important;
}

.reportDescription {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #666666 !important;
}

.corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #7DB3E278 !important;
    border-radius: 0 4px 0 32px;
}

.arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
    background-color: #7DB3E278 !important;
}

.reportCardContent {
    display: block;
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 32px 24px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    height: 40vh;
}

.reportCardContent:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    /* background: #00838d; */
    /* background: rgb(7, 91, 128); */
    background: #7DB3E278 !important;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.reportCardContent:hover:before {
    transform: scale(21);
}

.reportCardContent:hover .reportDescription {
    transition: all 0.3s ease-out !important;
    color: #414141 !important;
}

.reportCardContent:hover .reportTitle {
    transition: all 0.3s ease-out !important;
    /* color: #ffffff !important; */
}