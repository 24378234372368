/* @import "~react-image-gallery/styles/css/image-gallery.css"; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.themeDiv {
  margin-top: 32px;
  border-radius: 50px;
  background: #b2beed;
  display: inline-flex;
  float: right;
  position: sticky;
}

.text-center {
  text-align: center;
}

.MuiPhoneNumber-positionStart {
  display: inline-block !important;
  width: 30px !important;
  height: 30px !important;
}

.grid-container {
  width: 100% !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.MuiFormHelperText-root {
  color: red !important;
}

/* .MuiGrid-item {
  padding-left: 0px !important;
} */

.font-color {
  color: #fff !important;
}

.MuiButton-label {
  font-weight: bold;
}

/* Add this loading dots Css in particular js file */
.dot-loading:after {
  content: ' .';
  font-size: xx-large;
  animation: dots 1s steps(5, end) infinite;
  line-height: 10px;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #1976d2;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 #1976d2,
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 #1976d2,
      .5em 0 0 #1976d2;
  }
}

.grecaptcha-badge {
    visibility: hidden;
}