.tabPanelLg {
    width: 88vw !important;
}

.tabPanelSm {
    width: 95vw !important;
}

.tabPanelLg .MuiBox-root {
    padding: 10px !important;
}

.tabPanelSm .MuiBox-root {
    padding: 10px !important;
}